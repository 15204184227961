<template>
  <div style="height:50px; line-height: 50px; border-bottom: 1px solid #ccc; display: flex;">
    <div style="width: 200px; padding-left: 30px; font-weight: bold; color: blue;">好好吃点餐平台</div>
    <div style="flex:1"></div>
    <div style="width: 100px;">
          <el-image
                          style="width: 40px; height: 40px"
                          :src="this.nowuserdata.avatar"
                          :preview-src-list="[this.nowuserdata.avatar]"
                          fit="cover"
                          v-if="this.nowuser.role === 'role1'"
                        />
     <el-dropdown>
     
        <span class="el-dropdown-link"><br>
           {{ nowuserdata.name }}
          <el-icon class="el-icon--right">
            <arrow-down />
          </el-icon>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="$router.push('/person')" v-if="this.nowuser.role === 'role1'">个人信息</el-dropdown-item>
            <el-dropdown-item @click="$router.push('/password')">修改密码</el-dropdown-item>
            <el-dropdown-item @click="$router.push('/')">退出账号</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import request from '@/utils/requests'
export default {
  name: "Header",
  props: ['user'],
  data() {
    return {
      nowuser: {},
      nowuserdata: {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      let str = sessionStorage.getItem("user")
      console.log(str)
      this.nowuser = JSON.parse(str)
      this.nowuserdata = JSON.parse(this.nowuser.data)
       //console.log(this.nowuser.role)
      // console.log(this.nowuser.name)
      // console.log(str)
    }
  }
}
</script>

<style scoped>

</style>