<template>
  <el-menu
      style="width: 200px;min-height: calc(100vh - 50px)"
      default-active="canteenone"
      router
      class="el-menu-vertical-demo"
  >
    <el-menu-item index="canteenone" v-if="nowuserdata.merchantId === 1 || nowuser.role === 'role1'">
      <span style=" margin-left: 10px;">一食堂</span>  <span style="color:gold; margin-left: 20px;">评分：{{myData[1]}}</span>
    </el-menu-item>
    <el-menu-item index="canteentwo" v-if="nowuserdata.merchantId === 2 || nowuser.role === 'role1'">
          <span style=" margin-left: 10px;">二食堂  </span>  <span  style="color:gold; margin-left: 20px;">    评分：{{myData[2]}}</span>
    </el-menu-item>
    <el-menu-item index="canteenthree" v-if="nowuserdata.merchantId === 3 || nowuser.role === 'role1'">
          <span style=" margin-left: 10px;">三食堂   </span>  <span  style="color:gold; margin-left: 20px;" >   评分：{{myData[3]}}</span>
    </el-menu-item>
     <el-menu-item index="order" v-if="nowuser.role === 'role1'">
          <span style=" margin-left: 10px;">订单管理  </span>
    </el-menu-item>
     <el-menu-item index="displaydata" v-if="nowuser.role === 'role2'">
          <span style=" margin-left: 10px;">数据统计  </span>
    </el-menu-item>
   
    
  </el-menu>
</template>

<script>
import request from '@/utils/requests'
import {Food} from "@element-plus/icons-vue"

export default {
  name: "Aside",
   props: ['user'],
  data() {
    return {
      nowuser: {},
      nowuserdata: {},
      myData: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      let str = sessionStorage.getItem("user")
     // console.log(str)
      this.nowuser = JSON.parse(str)
      this.nowuserdata = JSON.parse(this.nowuser.data)

      request.get("/merchant/selectStar").then(res => {
       // console.log(res);
          let obj = JSON.parse(res.data)
          this.myData = obj
          // console.log(obj)
          // console.log(this.myData)
          // console.log(this.tableData.createTime);
        })
       //console.log(this.nowuser.role)
      // console.log(this.nowuser.name)
      // console.log(str)
    }
  },
  setup() {
    return {
      Food
    }
  }
}
</script>

<style scoped>

</style>