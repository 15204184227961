import { createRouter, createWebHistory } from 'vue-router'

import Layout from "@/layout/Layout.vue";
import Layoutadmin from "@/layout/Layoutadmin.vue";


const routes = [
  {
    path: '/layoutadmin',
    name: 'layoutadmin',
    component: Layoutadmin,
    redirect: "/user",      //路由重定向，自动跳转到home
    children: [
        {
          path: '/user',
          name: 'User',
          component: () => import("@/views/User"),
        },
      {
        path: '/business',
        name: 'Business',
        component: () => import("@/views/Business"),
      }
    ]
  },
  {
    path: '/layout',
    name: 'layout',
    component: Layout,
    redirect: "/canteenone",      //路由重定向，自动跳转到home
    children: [
        {
          path: '/canteenone',
          name: 'Canteenone',
          component: () => import("@/views/Canteenone"),
        },
        {
          path: '/canteentwo',
          name: 'Canteentwo',
          component: () => import("@/views/Canteentwo"),
        },
        {
          path: '/canteenthree',
          name: 'Canteenthree',
          component: () => import("@/views/Canteenthree"),
        },
        {
          path: '/password',
          name: 'Password',
          component: () => import("@/views/Password"),
        },
        {
          path: '/person',
          name: 'Person',
          component: () => import("@/views/Person"),
        },
        {
          path: '/order',
          name: 'Order',
          component: () => import("@/views/Order"),
        },
        {
          path: '/displaydata',
          name: 'Displaydata',
          component: () => import("@/views/Displaydata"),
        }  
    ]
  },
  {
    path: '/',
    name: 'Login',
    component: () => import("@/views/Login"),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import("@/views/Register"),
  },
  {
    path: '/shopping',
    name: 'Shopping',
    component: () => import("@/views/Shopping"),
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
