<template>
  <el-menu
      style="width: 200px;min-height: calc(100vh - 50px)"
      default-active="user"
      router
      class="el-menu-vertical-demo"
  >
    <el-menu-item index="user">
      <el-icon><icon-menu /></el-icon>
      <span>用户管理</span>
    </el-menu-item>
    <el-menu-item index="business" >
      <el-icon><document /></el-icon>
      <span>商家管理</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "Asideadmin"
}
</script>

<style scoped>

</style>