<template>
  <div>
    <Headeradmin />
    <!-- 主体 -->
    <div style="display: flex">
      <!-- 侧边栏 -->
      <Asideadmin />
      <!-- 内容区域 -->
      <router-view style="flex: 1"/>
    </div>
  </div>
</template>


<script>

import Headeradmin from "@/components/Headeradmin.vue"
import Asideadmin from "@/components/Asideadmin.vue"

export default {
  name: "Layoutadmin",
  components: {
    Headeradmin,
    Asideadmin
  }
}
</script>

<style scoped>

</style>